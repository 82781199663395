.sidebar-toolbar {
  width: 96px;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: var(--8, 8px) 0px 0px 0px;
  border-left: 1px solid transparent;
}
.sidebar-toolbar-left-border {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.sidebar-toolbar .sidebar-list {
  width: 88px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
}
.sidebar-toolbar .chatbot-menu-info {
  margin: 0 auto;
}
.sidebar-toolbar__item,
.sidebar-toolbar .chatbot-menu-info {
  width: 88px;
  height: 72px;
  padding: var(--8, 8px) 0 var(--8, 8px);
  border-radius: var(--8, 8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  box-sizing: border-box;
}
.sidebar-toolbar__item:hover, .sidebar-toolbar__item-active,
.sidebar-toolbar .chatbot-menu-info:hover,
.sidebar-toolbar .chatbot-menu-info-active {
  background: rgb(235, 239, 243);
  cursor: pointer;
}
.sidebar-toolbar__item-text,
.sidebar-toolbar .chatbot-menu-info-text {
  font-size: 10px;
  text-align: center;
  color: rgb(25, 29, 40);
}
.sidebar-toolbar .icon-box {
  width: 46px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-toolbar .hkgai-icon-ai-file,
.sidebar-toolbar .hkgai-icon-ai-copilot,
.sidebar-toolbar .hkgai-icon-outline-writing {
  width: 28px;
  height: 34px;
}
.sidebar-toolbar .hkgai-icon-ai-bot,
.sidebar-toolbar .hkgai-icon-ai-feedback {
  width: 36px;
  height: 36px;
}

.sub-menu {
  width: 150px;
  background: var(--bg-2, rgb(255, 255, 255));
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  gap: 4px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
}
.sub-menu__item {
  width: Fill 140px px;
  height: var(--40);
  padding: 9px 12px 9px 12px;
  border-radius: 2px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-1, rgb(29, 33, 41));
  display: flex;
  gap: 8px;
  align-items: center;
}
.sub-menu__item:hover {
  background: rgb(242, 243, 245);
  cursor: pointer;
}