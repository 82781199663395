@charset "UTF-8";
.ai-chat-message {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 16px;
}
.ai-chat-message .icon-rotate {
  animation: rotate 1s infinite linear; /* 3秒内完成一次旋转，无限循环 */
}
.ai-chat-message-action {
  display: flex;
  gap: 8px;
}
.ai-chat-message-action .icon-box {
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: var(--8, 8px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ai-chat-message-action .icon-box:hover {
  background: var(--black-5, rgba(28, 28, 28, 0.05));
}
.ai-chat-message-action .icon-box .hkgai-icon {
  width: 20px;
  height: 20px;
}
.ai-chat-message .ai-chat-message-wrap {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.ai-chat-message .ai-chat-message-wrap-reverse {
  max-width: 80%;
  justify-content: flex-end;
}
.ai-chat-message .ai-chat-message-wrap .ai-chat-message-action {
  margin-top: 6px;
  margin-bottom: 6px;
  visibility: hidden;
}
.ai-chat-message .ai-chat-message-wrap .ai-chat-message-action-show {
  visibility: visible;
}
.ai-chat-message .ai-chat-message-wrap .ai-chat-message-action .hkgai-icon-copy path {
  fill: #4E5969;
}
.ai-chat-message .ai-chat-message-wrap .ai-chat-message-action .hkgai-icon-therefresh path {
  fill: #4E5969;
}
.ai-chat-message .ai-chat-message-wrap:hover .ai-chat-message-action {
  visibility: visible;
}
.ai-chat-message .ai-chat-message-wrap-reverse:hover .action-copy-wrap {
  visibility: visible;
}
.ai-chat-message .action-copy-wrap {
  margin-left: 2px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  visibility: hidden;
}
.ai-chat-message .action-copy-wrap .icon-box {
  margin-top: 4px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: var(--8, 8px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ai-chat-message .action-copy-wrap .icon-box:hover {
  background: var(--black-5, rgba(28, 28, 28, 0.05));
}
.ai-chat-message .action-copy-wrap .icon-box .hkgai-icon-copy {
  width: 20px;
  height: 20px;
}
.ai-chat-message .action-copy-wrap .icon-box path {
  fill: #4E5969;
}
.ai-chat-message .action-list {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ai-chat-message .action-list .icon-content {
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: var(--8, 8px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ai-chat-message .action-list .icon-content:hover {
  background: var(--black-5, rgba(28, 28, 28, 0.05));
}
.ai-chat-message .action-list .hkgai-icon {
  width: 20px;
  height: 20px;
}

.ai-chat-message-list {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ai-chat-message-list .ai-chat-message-quote-wrap {
  margin-top: 16px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.ai-chat-message-list .ai-chat-message-quote-wrap .quote-text {
  flex: 1 1 auto;
  text-align: left;
  color: #4E5969;
  font-size: 13px;
  font-family: "PingFang SC";
  line-height: 18px;
}
.ai-chat-message-list .ai-chat-message-feedback-succ-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 64px;
}
.ai-chat-message-list .ai-chat-message-feedback-succ-wrap .title {
  text-align: left;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 16px;
  color: #86909C;
  font-family: "PingFang SC";
}
.ai-chat-message-list .ai-chat-message-feedback-wrap {
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid #E5E6EB;
  background-color: #F2F3F5;
  padding: 8px;
}
.ai-chat-message-list .ai-chat-message-feedback-wrap .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ai-chat-message-list .ai-chat-message-feedback-wrap .header .title {
  margin-right: 8px;
  text-align: left;
  flex: 1 1 auto;
  font-size: 12px;
  line-height: 16px;
  color: #4E5969;
  font-family: "PingFang SC";
}
.ai-chat-message-list .ai-chat-message-feedback-wrap .tags {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.ai-chat-message-list .ai-chat-message-feedback-wrap .tags .tag {
  cursor: pointer;
  margin: 0 10px 8px 0;
  padding: 0 8px;
  height: 28px;
  background-color: #FFFFFF;
  border: 1px solid #E5E6EB;
  border-radius: 8px;
  line-height: 26px;
  font-size: 14px;
  font-family: "PingFang SC";
  color: #1D2129;
}
.ai-chat-message-list .ai-chat-message-feedback-wrap .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ai-chat-message-list .ai-chat-message-feedback-wrap .footer .feedback-input {
  border-radius: 8px;
  flex: 1 1 auto;
  margin-right: 10px;
  background-color: #FFFFFF;
}
.ai-chat-message-list .ai-chat-message-feedback-wrap .footer .feedback-input::placeholder {
  color: #86909C;
}
.ai-chat-message-list .ai-chat-message-feedback-wrap .footer .feedback-submit {
  padding: 0 16px;
  border-radius: 8px;
}
.ai-chat-message-list .ai-chat-message-content {
  display: flex;
  justify-content: space-between;
}
.ai-chat-message-list .ai-chat-message-content-reverse {
  justify-content: flex-end;
}
.ai-chat-message-list .ai-chat-message-content .ai-chat-message-markdown-render {
  padding: 0 var(--16, 16px);
  border-radius: 16px 16px 16px 0;
  font-family: "PingFang SC";
}
.ai-chat-message-list .ai-chat-message-content .ai-chat-message-markdown-render {
  background: #EDF2F8;
  color: #222222;
  text-align: left;
}
.ai-chat-message-list .ai-chat-message-content .ai-chat-message-markdown-render *::selection {
  background-color: rgba(22, 93, 255, 0.2);
}
.ai-chat-message-list .ai-chat-message-wrap-reverse .ai-chat-message-markdown-render {
  padding: var(--12, 12px) var(--16, 16px);
  border-radius: 16px 16px 0 16px;
  background: #4080FF;
  font-size: 14px;
  font-weight: 400;
  color: rgb(255, 255, 255);
}
.ai-chat-message-list .loader-dots {
  margin-left: 20px;
  display: inline-block;
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 14px 0 #000, -14px 0 rgba(0, 0, 0, 0.1333333333);
    background: #000;
  }
  33% {
    box-shadow: 14px 0 #000, -14px 0 rgba(0, 0, 0, 0.1333333333);
    background: rgba(0, 0, 0, 0.1333333333);
  }
  66% {
    box-shadow: 14px 0 rgba(0, 0, 0, 0.1333333333), -14px 0 #000;
    background: rgba(0, 0, 0, 0.1333333333);
  }
  100% {
    box-shadow: 14px 0 rgba(0, 0, 0, 0.1333333333), -14px 0 #000;
    background: #000;
  }
}