@charset "UTF-8";
.ai-bot-shuffle-case {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}
.ai-bot-shuffle-case__title {
  display: flex;
  align-items: center;
}
.ai-bot-shuffle-case__title .clockwise-button-sync {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ai-bot-shuffle-case__title .hkgai-icon-arrows-clockwise-sync {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.ai-bot-shuffle-case__title .icon-rotate {
  animation: rotate 1s infinite linear; /* 3秒内完成一次旋转，无限循环 */
}
.ai-bot-shuffle-case__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ai-bot-shuffle-case .shuffle-case {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #F7FAFF;
  padding: var(--8, 8px);
  border-radius: var(--8, 8px);
  cursor: pointer;
}
.ai-bot-shuffle-case .shuffle-case:hover {
  background-color: var(--FAFAFA, rgb(250, 250, 250));
}
.ai-bot-shuffle-case .shuffle-case__title {
  margin-right: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "PingFang SC";
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
  line-height: 20px;
}
.ai-bot-shuffle-case .hkgai-icon-MagicPencil {
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
}
.ai-bot-shuffle-case .hkgai-icon-arrow-line-right {
  flex: 0 0 auto;
  color: rgb(153, 153, 153);
  font-size: 18px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}