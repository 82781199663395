.feedback {
  padding: 0 16px 0;
  height: 100%;
  background-color: rgb(239, 247, 255);
  border-radius: var(--8, 8px) 0px 0px 0px;
}
.feedback-title {
  padding: 16px 0;
  font-size: 20px;
  font-weight: 500;
}
.feedback-sub-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--666, rgb(102, 102, 102));
}
.feedback-from .arco-form-label-item {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
}
.feedback-from .arco-textarea {
  height: 124px;
  padding: var(--16, 16px);
  border-radius: var(--8, 8px);
  resize: none;
  caret-color: #165dff;
  background-color: #fff;
}
.feedback-from .arco-upload {
  display: block;
}
.feedback .upload-target {
  padding: 50px var(--16, 16px) 50px var(--16, 16px);
  gap: var(--24, 24px);
  border-radius: var(--8, 8px);
  border: 1px dashed var(--border-2, rgb(229, 230, 235));
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  background-color: #fff;
}
.feedback .upload-target .upload-tips {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: var(--text-3, rgb(134, 144, 156));
}
.feedback .button-box {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
}
.feedback .button-box .arco-btn {
  width: 300px;
  height: 44px;
  padding: var(--12, 12px) 0px;
  gap: 0px;
  border-radius: var(--8, 8px);
}
.feedback .arco-form-item-control-children {
  display: flex;
  flex-direction: column-reverse;
}
.feedback .arco-form-item-control-children .arco-upload-list {
  margin-top: 16px;
}

.render-upload-list {
  display: flex;
}
.render-upload-list .upload-item,
.render-upload-list .upload-image-preview,
.render-upload-list .image-preview-mask,
.render-upload-list .arco-image-img {
  width: var(--80, 80px);
  height: var(--80, 80px);
  border-radius: 2px;
}
.render-upload-list .upload-item {
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.render-upload-list .upload-item .image-preview-mask {
  display: none;
  background-color: var(--color-mask-bg);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.render-upload-list .upload-item:hover .image-preview-mask {
  display: flex;
  align-items: center;
  justify-content: center;
}
.render-upload-list .upload-item .arco-icon {
  margin: 0 8px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}