.sidebar-drawer {
  height: 100%;
  position: relative;
  transform: translateX(0);
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  width: 0;
  border-radius: var(--8, 8px) 0px 0px 0px;
}
.sidebar-drawer-title {
  padding: 16px 20px 12px;
  font-size: 20px;
  font-weight: 500;
}
.sidebar-drawer-body {
  height: 100%;
}
.sidebar-drawer.transition-entered {
  opacity: 1;
  transform: translateX(0);
  overflow: inherit;
  width: 360px;
}
.sidebar-drawer.transition-exited {
  opacity: 0;
  transform: translateX(100%);
  width: 0;
}
.sidebar-drawer .close-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px 0px rgba(35, 47, 65, 0.1);
}
.sidebar-drawer .close-button .arco-btn {
  background-color: #fff;
}
.sidebar-drawer .close-button .arco-btn:hover {
  color: #ebeff3;
}
.sidebar-drawer .close-button .arco-icon {
  color: rgb(28, 28, 28);
}