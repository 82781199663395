:root {
  --proofreading-common-color: #165dff;
  --proofreading-proper-names-color: #ff7d00;
}

.proofreading {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.proofreading-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 12px;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
.proofreading-title .proofreading-btn-reload {
  height: 28px;
  border-radius: 4px;
  padding: 0 6px;
  line-height: 20px;
}
.proofreading-title .proofreading-btn-reload .proofreading-btn-reload-icon {
  margin-right: 2px;
  font-size: 16px;
  vertical-align: text-bottom;
}

.proofreading-body {
  height: calc(100vh - 64px - 8px - 56px);
  background: linear-gradient(337.56deg, #ffffff 41.86%, rgba(22, 93, 255, 0.0666666667) 96.5%);
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proofreading-content {
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.proofreading-content .proofreading-state-desc {
  color: #222;
  display: block;
  margin-top: -45px;
}
.proofreading-content .proofreading-state-desc svg {
  font-size: 14px;
  margin-right: 6px;
}

.hkgai-icon-proofreading-empty-state {
  font-size: 200px;
}

.hkgai-icon-proofreading-start-state {
  font-size: 200px;
}

.proofreading-start-state,
.proofreading-empty-state {
  text-align: center;
  position: absolute;
  margin-top: -45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.proofreadDdlWrapper {
  max-width: 450px;
  border-radius: var(--8, 8px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.08);
  min-width: 340px;
  min-height: 80px;
  padding: 12px;
}
.proofreadDdlWrapper .proofreadDdlTitle {
  position: relative;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 12px;
}
.proofreadDdlWrapper .proofreadDdlTitle.proofreadDdlTitleCommon::after {
  background-color: var(--proofreading-common-color);
}
.proofreadDdlWrapper .proofreadDdlTitle.proofreadDdlTitleProper::after {
  background-color: var(--proofreading-proper-names-color);
}
.proofreadDdlWrapper .proofreadDdlTitle::after {
  background-color: var(--proofreading-common-color);
  display: block;
  height: 28px;
  width: 2px;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.proofreadDdlWrapper .proofreadDdlTitle .proofreadDdlTitleSub {
  color: var(--999, #999);
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
}
.proofreadDdlWrapper .originalBox {
  width: 100%;
  height: 20px;
  color: #222;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.proofreadDdlWrapper .originalBox.line-through {
  text-decoration-line: line-through !important;
  text-decoration-color: #ff0004;
  text-decoration-thickness: 2px;
}
.proofreadDdlWrapper .targetBox {
  width: 100%;
  height: 36px;
  margin: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--999, #999);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.proofreadDdlWrapper .targetBox .targetBoxLabel {
  width: 68px;
}
.proofreadDdlWrapper .targetBox .targetBoxContent {
  height: 34px;
  max-width: calc(100% - 68px);
  margin-bottom: 0;
  padding: 8px 12px;
  border-radius: 4px;
  background: rgba(22, 93, 255, 0.1);
  color: #165dff;
}
.proofreadDdlWrapper .operation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 28px;
  overflow: hidden;
}
.proofreadDdlWrapper .operation .operation_lable {
  margin-bottom: 0;
  flex: 1;
  align-self: center;
  padding-right: 30px;
  overflow: hidden;
  color: var(--999, #999);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  word-break: keep-all;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.proofreadDdlWrapper .operation .operation_btn_group {
  align-self: flex-start;
}
.proofreadDdlWrapper .operation .accept,
.proofreadDdlWrapper .operation .dismiss {
  height: 28px;
  font-size: 12px;
  border-radius: 4px;
  padding: 0 8px;
}

.total-space {
  font-size: 14px;
  color: #222;
  line-height: 20px;
  margin-bottom: 12px;
}
.total-space .total-space-label {
  font-weight: 500;
}

.main-catalog-main {
  overflow-x: auto;
}

.main-catalog-group {
  margin-bottom: 12px;
  display: flex;
}
.main-catalog-group .btn-main {
  padding: 8px 12px;
  margin-right: 8px;
  white-space: nowrap;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: #222;
}
.main-catalog-group .btn-main.arco-checkbox-checked {
  border: 1px solid var(--proofreading-common-color);
  background: #fff;
  color: #222;
}
.main-catalog-group .btn-main:hover {
  border: 1px solid var(--proofreading-common-color);
  background: #fff;
  color: #222;
}

.sub-catalog-button {
  margin-bottom: 16px;
}
.sub-catalog-button .btn-sub {
  height: 32px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #fff;
  color: #222;
  background: #fff;
  padding: 0 0 0 8px;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: #222;
}
.sub-catalog-button .btn-sub:hover {
  border: 1px solid #e5e5e5;
  background: #fff;
  color: #222;
}
.sub-catalog-button .btn-sub.btn-common:hover {
  border: 1px solid var(--proofreading-common-color);
  background: rgba(22, 93, 255, 0.1);
  color: #222;
}
.sub-catalog-button .btn-sub.btn-common:hover .count {
  color: var(--proofreading-common-color);
}
.sub-catalog-button .btn-sub.btn-proper-names:hover {
  border: 1px solid var(--proofreading-proper-names-color);
  background: rgba(255, 125, 0, 0.1);
  color: #222;
}
.sub-catalog-button .btn-sub.btn-proper-names:hover .count {
  color: var(--proofreading-proper-names-color);
}
.sub-catalog-button .btn-sub.active-sub.btn-common, .sub-catalog-button .btn-sub.active-sub.btn-common:hover {
  border: 1px solid var(--proofreading-common-color);
  background: rgba(22, 93, 255, 0.1);
  color: #222;
}
.sub-catalog-button .btn-sub.active-sub.btn-common .count, .sub-catalog-button .btn-sub.active-sub.btn-common:hover .count {
  color: var(--proofreading-common-color);
}
.sub-catalog-button .btn-sub.active-sub.btn-proper-names, .sub-catalog-button .btn-sub.active-sub.btn-proper-names:hover {
  border: 1px solid var(--proofreading-proper-names-color);
  background: rgba(255, 125, 0, 0.1);
  color: #222;
}
.sub-catalog-button .btn-sub.active-sub.btn-proper-names .count, .sub-catalog-button .btn-sub.active-sub.btn-proper-names:hover .count {
  color: var(--proofreading-proper-names-color);
}
.sub-catalog-button .btn-sub .btn-icon {
  font-size: 16px;
  padding: 8px;
  margin-left: 0;
  width: 32px;
  height: 32px;
}

.proofreading-collapse {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 1px;
  border: none;
  flex: 1;
}

.proofreading-collapse-item {
  overflow: hidden;
  border: 1px solid var(--color-neutral-3);
  border-radius: 4px;
  margin-bottom: 12px;
  position: relative;
}
.proofreading-collapse-item .arco-collapse-item-header {
  padding-left: 22px;
  border-bottom: none;
}
.proofreading-collapse-item .arco-collapse-item-header .arco-collapse-item-header-title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: normal !important;
}
.proofreading-collapse-item .arco-collapse-item-header .arco-collapse-item-header-title .proofreading-collapse-item-header {
  width: 100%;
}
.proofreading-collapse-item .arco-collapse-item-header .arco-collapse-item-header-title .proofreading-collapse-item-header::after {
  background-color: var(--proofreading-common-color);
  display: block;
  height: 28px;
  width: 2px;
  content: "";
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.proofreading-collapse-item .arco-collapse-item-header .arco-collapse-item-header-title .proofreading-collapse-item-header.collapse-common-errors::after {
  background-color: var(--proofreading-common-color);
}
.proofreading-collapse-item .arco-collapse-item-header .arco-collapse-item-header-title .proofreading-collapse-item-header.collapse-proper-names::after {
  background-color: var(--proofreading-proper-names-color);
}
.proofreading-collapse-item .arco-collapse-item-header .arco-collapse-item-header-title .proofreading-collapse-item-header .proofreading-collapse-item-header-title {
  width: 80%;
}
.proofreading-collapse-item .arco-collapse-item-header .arco-collapse-item-header-title .proofreading-collapse-item-header .proofreading-collapse-item-header-title .proofreading-collapse-item-header-sub {
  color: var(--999, #999);
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.proofreading-collapse-item .arco-collapse-item-header .arco-collapse-item-header-title .proofreading-collapse-item-ellipsis {
  width: 80%;
  margin-bottom: 0;
  height: 20px;
  line-height: 20px;
  text-decoration-line: none;
}
.proofreading-collapse-item .arco-collapse-item-header .proofreading-collapse-item-extra {
  color: var(--proofreading-common-color);
}
.proofreading-collapse-item.arco-collapse-item-active .proofreading-collapse-item-extra {
  display: none;
}
.proofreading-collapse-item:last-of-type {
  margin-bottom: 0px;
  border-bottom: 1px solid var(--color-neutral-3);
}
.proofreading-collapse-item .arco-collapse-item-content {
  background-color: #fff;
}
.proofreading-collapse-item .arco-collapse-item-content .arco-collapse-item-content-box {
  padding: 0 12px 12px;
}
.proofreading-collapse-item .proofreading-collapse-suggestion {
  display: flex;
  align-items: center;
  color: #999;
  font-size: 12px;
}
.proofreading-collapse-item .proofreading-collapse-suggestion .proofreading-collapse-suggestion-label {
  padding-right: 8px;
}
.proofreading-collapse-item .proofreading-collapse-suggestion .proofreading-collapse-suggestion-content {
  flex: 1;
  height: 38px;
  line-height: 22px;
  overflow: hidden;
  margin-bottom: 0;
  padding: 8px 12px;
  border-radius: 4px;
  background: rgba(22, 93, 255, 0.1);
  color: #165dff;
}
.proofreading-collapse-item .proofreading-collapse-description {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  line-height: 24px;
  color: #999;
  font-size: 12px;
}
.proofreading-collapse-item .proofreading-collapse-description .proofreading-collapse-description-label {
  line-height: 18px;
  max-width: 200px;
}
.proofreading-collapse-item .proofreading-collapse-description .proofreading-collapse-description-content {
  flex: 1;
  overflow: hidden;
  margin-bottom: 0;
  color: #999;
}
.proofreading-collapse-item .proofreading-collapse-description .proofreading-collapse-description-action .action-btn {
  padding: 0 8px;
  margin-right: 4px;
}
.proofreading-collapse-item .proofreading-collapse-description .proofreading-collapse-description-action .action-link {
  font-size: 12px;
}
.proofreading-collapse-item .proofreading-collapse-proper-names {
  padding-top: 4px;
  line-height: 20px;
  font-size: 12px;
  color: #999;
}
.proofreading-collapse-item .proofreading-collapse-proper-names .proofreading-collapse-proper-left {
  width: 80%;
}
.proofreading-collapse-item .proofreading-collapse-proper-names .proofreading-collapse-proper-right {
  display: block;
  font-size: 12px;
}