.sider-page .arco-list-pagination {
  margin-top: 0;
}
.sider-page .arco-pagination-item-simple-pager {
  margin: 0;
}
.sider-page .arco-pagination-item-simple-pager .arco-pagination-jumper-separator {
  padding: 0;
}
.sider-page .sider-list {
  margin-left: 8px;
  margin-right: 8px;
  width: auto;
}
.sider-page .sider-list .sider-list-item {
  cursor: pointer;
  flex-grow: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: var(--8, 8px);
  background: rgb(255, 255, 255);
}
.sider-page .sider-list .sider-list-item-dis {
  background: rgb(247, 249, 251);
}
.sider-page .sider-list .sider-list-item .item-body-view {
  margin-left: 8px;
  margin-right: 8px;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .icon-view {
  display: flex;
  align-items: center;
  height: 20px;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .icon-view .icon {
  width: 16px;
  height: 16px;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .content {
  display: flex;
  flex-grow: 1;
  margin-left: 4px;
  height: 20px;
  align-items: center;
  overflow: hidden;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .content .arco-input {
  flex-grow: 1;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 10px;
  font-size: 14px;
  color: rgb(34, 34, 34);
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .content .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: rgb(34, 34, 34);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .menu {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .menu .copy-icon {
  margin-right: 4px;
  width: 16px;
  height: 16px;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .menu .delete-icon {
  width: 16px;
  height: 16px;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .menu .delete-icon-dis {
  opacity: 0.3;
  cursor: not-allowed;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-top-view .menu .edit-icon {
  margin-right: 4px;
  width: 16px;
  height: 16px;
}
.sider-page .sider-list .sider-list-item .item-body-view .item-bottom-view {
  margin-left: 20px;
  font-weight: 400;
  font-size: 10px;
  color: rgb(102, 102, 102);
  overflow: hidden;
  text-overflow: ellipsis;
}