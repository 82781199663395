.user-setting-bar .bar-box {
  margin: 0 24px;
  color: rgb(113, 128, 150);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.language-menu {
  display: flex;
  padding: var(--8, 8px);
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--16, 16px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: linear-gradient(0deg, var(--white-20, rgba(255, 255, 255, 0.2)) 0%, var(--white-20, rgba(255, 255, 255, 0.2)) 100%), var(--white-80, rgba(255, 255, 255, 0.8));
  backdrop-filter: blur(20px);
  box-shadow: none;
}
.language-menu-item {
  width: 128px;
  display: flex;
  padding: var(--8, 8px);
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: var(--8, 8px);
  color: var(--black-100, #1c1c1c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.language-menu-item:hover {
  background: #f1f2f3;
}
.language-menu-item .arco-icon-check,
.language-menu-item span {
  width: 20px;
  height: 20px;
  color: #4e5969;
  margin-right: 8px;
  display: inline-block;
}