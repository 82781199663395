.page-ai-bg {
  background-color: #ffffff;
  background-size: cover;
}

.custom-header {
  user-select: none;
}
.custom-header .arco-page-header {
  padding: 10px 0;
}
.custom-header .arco-avatar {
  width: 28px;
  height: 28px;
  background: #6585fa;
  font-size: 14px;
}
.custom-header .layout-head {
  height: 64px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-header .layout-head-content {
  display: flex;
  align-items: center;
  gap: 48px;
}
.custom-header .layout-head-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 48px 0 24px;
}
.custom-header .layout-head-actions__button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #222;
}
.custom-header .layout-head-actions__button .hkgai-icon {
  font-size: 20px;
}
.custom-header .layout-head-actions .arco-image img {
  width: 292px;
  height: 40px;
  border-radius: 8px;
}
.custom-header .layout-head-extra {
  width: 96px;
  display: flex;
  align-items: center;
}
.custom-header .icon-copilot-wrap {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: transform 3s linear;
}
.custom-header .icon-copilot-wrap .hkgai-icon-arrow-line-right {
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
}
.custom-header .icon-copilot-wrap:hover {
  border-radius: var(--8, 8px);
  background: rgba(0, 0, 0, 0.04);
}
.custom-header .icon-copilot-wrap:hover .hkgai-icon-arrow-line-right {
  transform: rotate(270deg);
}

.page-footer {
  font-size: 12px;
  color: #8e91a9;
  padding: 0px 30px;
  user-select: none;
}

.alert-warning-wrap {
  position: fixed;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.head-dropdown-list {
  padding: var(--12, 12px) var(--16, 16px);
  border-radius: var(--8, 8px);
  background: rgb(255, 255, 255);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  font-size: 14px;
  font-weight: 400;
}
.head-dropdown-list .head-dropdown-menu-item {
  cursor: pointer;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
}

.sider-layout {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.sider-layout .section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
}

.arco-card-body {
  background-color: rgba(0, 0, 0, 0);
}

.arco-btn-size-mini.arco-btn-shape-circle {
  width: 20px;
  height: 20px;
}

.notificati-dropdown {
  width: 342px;
  height: 360px;
  border-radius: var(--8, 8px);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(226, 230, 240);
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.globalRightSider .arco-layout-sider-children {
  overflow: initial;
}