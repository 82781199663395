.ai-chat-bot {
  min-width: 580px;
  min-height: 740px;
  width: 35vw;
  height: 80vh;
  border-radius: 16px;
  border: 1px solid rgba(87, 84, 84, 0.05);
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: 10px;
  right: 106px;
  z-index: 300;
  display: flex;
  flex-direction: column;
  padding: 14px 0px 16px 0px;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease;
  transform-origin: 100% bottom;
}
.ai-chat-bot.transition-entered {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}
.ai-chat-bot.transition-exited {
  opacity: 0;
  transform: translate(50%, 50%) scale(0.5);
}
.ai-chat-bot__head {
  height: 96px;
  background: linear-gradient(to bottom, transparent 98%, #e5e6e8);
}
.ai-chat-bot__body {
  padding: 0 16px;
  flex: 1;
  overflow: hidden;
}
.ai-chat-bot__quote {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ai-chat-bot__quote:hover {
  background-color: #e5e6eb;
}
.ai-chat-bot .chat-message {
  width: 100%;
  height: 100%;
}
.ai-chat-bot .chat-message-scroll-hidden {
  position: relative;
  overflow-y: auto;
}

.ai-chat-bot-head {
  padding: 0 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ai-chat-bot-head .hkgai-icon-chatbox,
.ai-chat-bot-head .hkgai-icon-chatbox_shadow {
  position: absolute;
  left: 50%;
  top: 12px;
  width: 62px;
  height: 62px;
  transform: translateX(-50%);
}
.ai-chat-bot-head .hkgai-icon-chatbox_shadow {
  top: 11px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 31px;
}
.ai-chat-bot-head_title {
  flex: 0 0 auto;
  color: #222222;
  font-family: "PingFang SC";
  font-size: 14px;
}
.ai-chat-bot-head__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.ai-chat-bot-head__content .title {
  font-size: 14px;
  font-weight: 400;
  color: rgb(34, 34, 34);
}
.ai-chat-bot-head__content .clear-button {
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  color: #4e5969;
}
.ai-chat-bot-head__content .clear-button-dis {
  cursor: not-allowed;
  opacity: 0.2;
}
.ai-chat-bot-head__content .hkgai-icon-clear {
  width: 16px;
  height: 16px;
}
.ai-chat-bot-head__content .hkgai-icon-clear path {
  fill: #4e5969;
}
.ai-chat-bot-head .flex-block {
  flex: 1 1 auto;
}
.ai-chat-bot-head .close-button {
  margin-left: 12px;
  padding: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.ai-chat-bot-head .hkgai-icon-close-button {
  width: 16px;
  height: 16px;
  color: #4e5969;
}

.ai-chat-bot-footer-wap {
  padding: 0 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content {
  padding: 8px;
  border-radius: var(--8, 8px);
  border: 1.5px solid var(--Color, rgb(22, 93, 255));
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .reply-quote {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 8px;
  border-radius: 8px 8px 2px 2px;
  background-color: #e8f3ff;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .reply-quote .quote-text {
  flex: 1 1 auto;
  font-size: 13px;
  line-height: 18px;
  color: #4e5969;
  font-family: "PingFang SC";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 8px;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input .ai-chat-bot-footer-input-inner::placeholder {
  color: #86909c;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input .button-content {
  width: 24px;
  height: 24px;
  border-radius: var(--8, 8px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input .button-content:hover {
  background: var(--black-5, rgba(28, 28, 28, 0.05));
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input .hkgai-icon {
  cursor: pointer;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input .hkgai-icon-attachment {
  width: 20px;
  height: 20px;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input .hkgai-icon-send-btn {
  width: 20px;
  height: 20px;
  cursor: not-allowed;
  color: #86909c;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input .hkgai-icon-PaperPlaneRightBlue {
  width: 20px;
  height: 20px;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .ai-chat-bot-footer-input .hkgai-icon-stop-circle {
  width: 20px;
  height: 20px;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .arco-textarea {
  padding: 0;
  background: none;
  min-height: 16px;
  border: 0px;
  outline: none;
  caret-color: #165dff;
  font-size: 16px;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .arco-textarea::-webkit-resizer {
  display: none;
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .arco-textarea::placeholder {
  font-size: 16px;
  color: var(--e5e5e5, rgb(229, 229, 229));
}
.ai-chat-bot-footer-wap .ai-chat-bot-footer__content .arco-textarea::-webkit-scrollbar-track {
  background: var(--Select-menu, #f1f5f8);
}
.ai-chat-bot-footer-wap .actions-content {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ai-chat-bot-footer-wap .actions-content .actions-button {
  height: 36px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid rgb(226, 232, 240);
  background: rgb(255, 255, 255);
  padding: 0px var(--8, 8px) 0px var(--8, 8px);
  gap: var(--4, 4px);
  border-radius: var(--8, 8px);
  font-size: 14px;
  font-weight: 500;
  color: var(--222, rgb(34, 34, 34));
}
.ai-chat-bot-footer-wap .actions-content .actions-button > svg + span {
  margin-left: 0;
}
.ai-chat-bot-footer-wap .actions-content .actions-button .hkgai-icon-code {
  height: 16px;
  width: 16px;
}
.ai-chat-bot-footer-wap .links-content {
  padding-top: 16px;
  overflow-x: auto;
}
.ai-chat-bot-footer-wap .links-content .link-list {
  display: flex;
  gap: 16px;
}
.ai-chat-bot-footer-wap .links-content .link-tag {
  padding: 4px 10px 4px 4px;
  height: 32px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: var(--8, 8px);
  border-radius: var(--8, 8px);
  color: var(--black-100, rgb(0, 0, 0));
  font-size: 16px;
  font-weight: 400;
  background: rgb(240, 240, 240);
  position: relative;
}
.ai-chat-bot-footer-wap .links-content .link-tag .arco-tag-close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
}
.ai-chat-bot-footer-wap .links-content .link-tag .arco-tag-close-btn .hkgai-icon-close-circle-fill {
  width: 20px;
  height: 20px;
  color: rgb(153, 153, 153);
}

@media (max-width: 1280px) {
  .ai-chat-bot {
    position: fixed;
    bottom: 10px;
    right: 0;
  }
}